import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import rehypeReact from "rehype-react"

import Layout from "../components/layout"

export default ({ data }) => {
  const home = data.home

  const epics = home.frontmatter.epic.toString()

  const linkReadyEpics = epics.split(" ").join("-").toLowerCase()

  const newsStoriesReturns = data.newsCards
  const allNewsStories = newsStoriesReturns.edges
  const newsStoriesCount = allNewsStories.length

  const explainerStoriesReturns = data.explainerCards
  const allExplainerStories = explainerStoriesReturns.edges
  const explainerStoriesCount = allExplainerStories.length

  const stackStoriesReturns = data.stackCards
  const allStackStories = stackStoriesReturns.edges
  const stackStoriesCount = allStackStories.length

  const peopleReturns = data.people
  const allPeople = peopleReturns.edges
  const peopleCount = allPeople.length

  const renderAst = new rehypeReact({
    createElement: React.createElement
  }).Compiler

  return (
    <Layout>

      <div className="section">
        <div className="sections-nav">
          <div className="hero-image">
            <Img fluid={home.frontmatter.image.childImageSharp.fluid} />
          </div>

          <div className="epic-top-section">
            <div className="text">
              <h1>{home.frontmatter.title}</h1>
              <p>{home.frontmatter.blurb}</p>
              <div className="nav-section person-section">
                {
                  (peopleCount > 0) ?
                    <div className="news-stories-list">
                      <h4 className="label">
                        Related People
                      </h4>
                      {data.people.edges.map(({node}, index) => (
                        <Link to={node.fields.slug}>
                          <div className="ripple-link person-link">
                            <div className="ripple-image">
                              <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                            </div>
                            <div className="ripple-text person-text">
                              <h4 className="ripple-card-title">
                                {node.frontmatter.title}
                              </h4>
                              <h5 className="ripple-card-timestamp">
                                {node.frontmatter.blurb}
                              </h5>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  :
                    null
                }
              </div>
            </div>
          </div>
        </div>

        <div className="feed">

          {
            (newsStoriesCount > 0) ?
              <div className="stories-container">
                <h4 className="label">
                  Latest Stories
                </h4>
                {(newsStoriesCount > 2) ?
                  <div className="news-stories-carousel">
                    {data.newsCards.edges.map(({node}, index) => (
                      <Link to={node.fields.slug}>
                        <div className="carousel-card" key={index}>
                          <div className="carousel-card-image">
                            <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                          </div>
                          <div className="carousel-card-text">
                            <h4 className="carousel-card-title">
                              {node.frontmatter.title}
                            </h4>
                            <h5 className="carousel-card-timestamp">
                              {node.frontmatter.date}
                            </h5>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                :
                  <div className="news-stories-list">
                    {data.newsCards.edges.map(({node}, index) => (
                      <Link to={node.fields.slug}>
                        <div className="ripple-link">
                          <div className="ripple-image">
                            <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                          </div>
                          <div className="ripple-text">
                            <h4 className="ripple-card-title">
                              {node.frontmatter.title}
                            </h4>
                            <h5 className="ripple-card-timestamp">
                              {node.frontmatter.date}
                            </h5>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                }
              </div>
            :
              null
          }

          
                {
                  (peopleCount > 0) ?
                    <div className="stories-container person-feed-section">
                      <div className="news-stories-list">
                        <h4 className="label">
                          Related People
                        </h4>
                        {data.people.edges.map(({node}, index) => (
                          <Link to={node.fields.slug}>
                            <div className="ripple-link">
                              <div className="ripple-image">
                                <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                              </div>
                              <div className="ripple-text">
                                <h4 className="ripple-card-title">
                                  {node.frontmatter.title}
                                </h4>
                                <h5 className="ripple-card-timestamp">
                                  {node.frontmatter.blurb}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  :
                    null
                }

          <div className="mobile-stories-container stories-container">
            <h4 className="label">
              Primer
            </h4>
            <div className="primer-body">
              {renderAst(home.htmlAst)}
            </div>
          </div>

          {
            (explainerStoriesCount > 0) ?
              <div className="stories-container">
                <h4 className="label">
                  Go deeper
                </h4>
                {(explainerStoriesCount > 2) ?
                  <div className="news-stories-carousel">
                    {data.explainerCards.edges.map(({node}, index) => (
                      <Link to={node.fields.slug}>
                        <div className="carousel-card" key={index}>
                          <div className="carousel-card-image">
                            <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                          </div>
                          <div className="carousel-card-text">
                            <h4 className="carousel-card-title">
                              {node.frontmatter.title}
                            </h4>
                            <h5 className="carousel-card-timestamp">
                              {node.frontmatter.date}
                            </h5>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                :
                  <div className="news-stories-list">
                    {data.explainerCards.edges.map(({node}, index) => (
                      <Link to={node.fields.slug}>
                        <div className="ripple-link">
                          <div className="ripple-image">
                            <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                          </div>
                          <div className="ripple-text">
                            <h4 className="ripple-card-title">
                              {node.frontmatter.title}
                            </h4>
                            <h5 className="ripple-card-timestamp">
                              {node.frontmatter.date}
                            </h5>
                          </div>
                        </div>
                      </Link>
                    ))}
                    {data.stackCards.edges.map(({node}, index) => (
                      <Link to={node.fields.slug}>
                        <div className="ripple-link">
                          <div className="ripple-image">
                            <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                          </div>
                          <div className="ripple-text">
                            <h4 className="ripple-card-title">
                              {node.frontmatter.title}
                            </h4>
                            <h5 className="ripple-card-timestamp">
                              {node.frontmatter.date}
                            </h5>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                }
              </div>
          :
            (stackStoriesCount > 0) ?
              <div className="stories-container">
                <h4 className="label">
                  Go deeper
                </h4>
                <div className="news-stories-list">
                  {data.stackCards.edges.map(({node}, index) => (
                    <Link to={node.fields.slug}>
                      <div className="ripple-link">
                        <div className="ripple-image">
                          <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                        </div>
                        <div className="ripple-text">
                          <h4 className="ripple-card-title">
                            {node.frontmatter.title}
                          </h4>
                          <h5 className="ripple-card-timestamp">
                            {node.frontmatter.date}
                          </h5>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            :
              null
          }

        </div>

      </div>

    </Layout>
  )
}

export const query = graphql`
  query($epic: String!, $type: String!) {

    home: markdownRemark(
      frontmatter: {
        epic: {
          eq: $epic
        }
        type: {
          eq: "home"
        }
      }) {
      frontmatter {
        title
        blurb
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        type
        epic
        default
      }
      htmlAst
    }

    people: allMarkdownRemark(
      filter: {
        frontmatter: {
          epic: {
            eq: $epic
          }
          type: {
            eq: "people"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
            date(formatString: "MMMM Do")
          }
          html
          fields {
            slug
          }
        }
      }
    }

    newsCards: allMarkdownRemark(
      filter: {
        frontmatter: {
          epic: {
            eq: $epic
          }
          type: {
            eq: "news"
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 6
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
          htmlAst
        }
      }
    }

    explainerCards: allMarkdownRemark(
      filter: {
        frontmatter: {
          epic: {
            eq: $epic
          }
          type: {
            eq: "explainer"
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
          htmlAst
        }
      }
    }

    stackCards: allMarkdownRemark(
      filter: {
        frontmatter: {
          epic: {
            eq: $epic
          }
          type: {
            eq: "stack"
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
          htmlAst
        }
      }
    }

    types: allMarkdownRemark(
      filter: {
        frontmatter: {
          epic: {
            eq: $epic
          }
          type: {
            nin: ["home", "stack", "news"]
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            blurb
          }
          fields {
            slug
          }
        }
      }
    }

    typeCards: allMarkdownRemark(
      filter: {
        frontmatter: {
          epic: {
            eq: $epic
          }
          type: {
            eq: $type
            ne: "home"
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
            date(formatString: "MMMM Do")
          }
          html
          fields {
            slug
          }
        }
      }
    }

  }
`